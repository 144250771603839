import React from 'react';

import styles from '../DateBar/DateBar.module.css'

const Date = (props) => {

    const title = props.name;
    const id = props.id;

    const handleClick = () => {
        props.handleClick(id)
    }

    return (
        <button className={props.isActive ? `${styles.DateItem} active` : styles.DateItem} onClick={handleClick}>
            {title}
        </button>
    )
}

export default Date;