import React from 'react';

import Date from '../Date/Date'

import styles from './DateBar.module.css'

const DateBar = (props) => {

    const bookings = props.bookings

    const days = Object.entries(bookings)

    const handleClick = (day) => {
        props.setSelectedDate(day)
    }

    const dates = days.map(day =>
        <Date key={day[0]} id={day[0]} name={day[1].title} handleClick={handleClick} isActive={props.selectedDate === day[0] ? true : false} />
    )


    return (
        <div className={styles.DateBar}>
            {dates}
        </div>
    )
}

export default DateBar;