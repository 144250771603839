import React from 'react';

// import { Scrollbars } from 'react-custom-scrollbars';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';


import BookingTime from '../BookingTime/BookingTime';

import InnerContent from '../ContentContainer/ContentContainer.module.css'
import timelist from './TimeList.module.css'


let styles = {};

Object.assign(styles, InnerContent, timelist)

const TimeList = (props) => {

    const setSelectedMeeting = (meetingId) => {
        props.setSelectedMeeting(meetingId)
    }

    const times = props.times;

    let timeButtons;

    console.log('times, ', times)

    if (times) {
        // Sort so they appear in ascending date order
        times.sort((a, b) => a.data.timestamp - b.data.timestamp)

        timeButtons = times.map(time => 
            <BookingTime key={time.id} id={time.id} timestamp={time.data.timestamp} isDisabled={time.data.isBookable ? false : true} isActive={props.selectedMeetingId===time.id ? true : false} handleSelectMeeting={setSelectedMeeting}/>
            )
    }


    return (
        <div className={styles.InnerContent + ' ' /*+ styles.timelist*/}>
            <SimpleBar style={{height:'100%'}}>
                {timeButtons}
            </SimpleBar>    
        </div>
    )
}

export default TimeList;