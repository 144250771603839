import React, { useState } from 'react';

import { functions } from '../../services/firebase'
import moment from 'moment';

import SyncLoader from "react-spinners/ClipLoader";

import styles from '../ContentContainer/ContentContainer.module.css';


const BookingDetails = (props) => {

    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [awaitingSubmit, setAwaitingSubmit] = useState(false);

    if (!props.selectedMeeting) {
        return (
            <div className={`${styles.InnerContent} ${styles.BookingDetails}`}>
            <div>
                <h3>Select a Meeting</h3>
            </div>
        </div>            
        )
    }

    const contextData = props.contextData;


    const meeting = props.selectedMeeting;
    const timestamp = meeting.data.timestamp.toDate();


    const handleSubmit = (e) => {
        e.preventDefault();
        let submitBooking = functions.httpsCallable('bookMeeting');

        setAwaitingSubmit(true);
        submitBooking({event: contextData.event, meeting: contextData.meeting, id: meeting.id, email: email}).then(res => {
            console.log(res);
            setAwaitingSubmit(false);
            if (res.data.status == 'success') {
                setStatus('booked');
            } else {
                setStatus('error')
            }
            
        })
    }


    if (status === 'booked') {
        return (
            <div className={`${styles.InnerContent} ${styles.BookingDetails}`}>
            <div>
                <h3>Booking Successful</h3>
            </div>
        </div>            
        )        
    }

    if (status === 'error') {
        return (
            <div className={`${styles.InnerContent} ${styles.BookingDetails}`}>
            <div>
                <h3>An error occurred</h3>
                <div>Please try again</div>
            </div>
        </div>            
        )         
    }



    return (
        <div className={`${styles.InnerContent} ${styles.BookingDetails}`}>
            <div>
                <h3>Confirm Your Booking</h3>
            </div>
            <div className={styles.detailSection}>
                <div>{meeting.data.ownerName || ''}</div>
                <div>{moment(timestamp).format('dddd Do MMMM [at] HH[:]mm')}</div>
            </div>
            <div className={styles.detailSection}>
                <form onSubmit={e => handleSubmit(e)}>
                    <div><label htmlFor="email">Enter Your Email Address:</label></div>
                    <div><input type="email" name="email" onChange={e => setEmail(e.target.value)}/></div>
                    <div style={{fontSize: 'small', marginBottom: '10px'}}>By clicking submit, you agree for your email address to be shared with the exhibitor</div>
                    <div><input className={styles.submitButton} type="submit"/></div>
                </form>
            </div>
            <div>
                <SyncLoader loading={awaitingSubmit}/>
            </div>
            
        </div>
    )
}

export default BookingDetails;