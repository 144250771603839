import React from 'react';

import moment from 'moment';

import styles from './BookingTime.module.css';

const BookingTime = (props) => {

    const timestamp = props.timestamp.toDate();
    let displayTime;

    if (timestamp) {
        displayTime = moment(timestamp).format('HH[:]mm')
    } else {
        displayTime = 'Error'
    }

    const handleSelectMeeting = () => {
        props.handleSelectMeeting(props.id)
    }

    if (props.isDisabled) {
        return (
            <button className={`${styles.BookingTime} disabled`} disabled>
                {displayTime}
            </button>
        )
    }


    return (
        <button className={props.isActive ? `${styles.BookingTime} active` : styles.BookingTime} onClick={handleSelectMeeting}>
            {displayTime}
        </button>
    )
}

export default BookingTime;