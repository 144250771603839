import React from 'react';

import TimeList from '../TimeList/TimeList';
import BookingDetails from '../BookingDetails/BookingDetails';

import styles from './ContentContainer.module.css'

const ContentContainer = (props) => {

    const bookings = props.bookings;
    const selectedMeetingId = props.selectedMeetingId;

    let times;
    let selectedMeeting;

    if (bookings) {
        times = bookings.bookings
    }

    if (selectedMeetingId) {
        const selectedMeetingIndex = times.findIndex(meeting => meeting.id === selectedMeetingId);
        selectedMeeting = times[selectedMeetingIndex];
        console.log('Selected meeting: ', selectedMeeting)
    }

    // console.log(times)

    const setSelectedMeeting = (meetingId) => {
        props.setSelectedMeeting(meetingId)
    }

    return (
        <div className={styles.ContentContainer}>
            <TimeList times={times} selectedMeetingId={selectedMeetingId} setSelectedMeeting={setSelectedMeeting}/>
            <BookingDetails contextData={props.contextData} selectedMeeting={selectedMeeting}/>
        </div>
    )
}

export default ContentContainer;