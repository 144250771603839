import React, { useState } from 'react';

const Emails = (props) => {

    let emailList;

    const addEmail = props.addEmail;
    const removeEmail = props.removeEmail;
    const id = props.selectedBookableId;

    const [newEmail, setNewEmail] = useState('');

    if (props.selectedBookable.emails) {
        emailList = props.selectedBookable.emails.map(entry => {
            return <li style={{cursor: 'pointer'}} onClick={() => removeEmail(entry, id)} key={entry}>{entry}</li>
        })
    }

    return (
        <div>
            <div>
                <div>Emails for <strong>{props.selectedBookable.name}</strong>:</div>
                {emailList ? <ul>{emailList}</ul> : null}
            </div>
            <form className="new-email-form" onSubmit={e => addEmail(e, newEmail, id)}>
                <div>
                    <label htmlFor="new-email">New Email:</label>
                    <input type="email" name="new-email" onChange={e => setNewEmail(e.target.value)}/>
                </div>
                <div>
                    <input type="submit" />
                </div>
            </form>

        </div>
    )
}

export default Emails;