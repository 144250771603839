import React, {useEffect, useState} from 'react';

import { firestore } from '../../services/firebase';

import moment from 'moment';

const AddMeeting = (props) => {

    const [timestamp, setTimestamp] = useState();
    const [duration, setDuration] = useState(0);
    const [repeats, setRepeats] = useState(0);

    useEffect(() => {
        console.table({timestamp, duration, repeats})
    })


    if (!props.selectedBookable || (Object.keys(props.selectedBookable).length === 0 && props.selectedBookable.constructor === Object)) {
        return (
            <div>
                No selected bookable
            </div>
        )
    }



    const createMeeting = (e) => {
        e.preventDefault();

        let queue = [];

        if ((timestamp) && (repeats > 0) && (duration > 0)) {
            // do logic to batch em... will get back to it

            const startTime = new Date(timestamp);

            let momentDate = moment(startTime);

            for (var i = 0; i < repeats; i++) {

                const doc = {
                    timestamp: firestore.Timestamp.fromDate(momentDate.toDate()),
                    isBookable: true,
                    ownerName: props.selectedBookable.name,
                    duration: Number(duration),
                };

                // Add this item to the queue
                queue.push(doc);

                // Update the moment date object ready for the next iteration
                momentDate.add(duration, 'm');
            }
            

        } else {
            // No need to batch...

            const doc = {
                timestamp: firestore.Timestamp.fromDate(new Date(timestamp)),
                isBookable: true,
                ownerName: props.selectedBookable.name,
                duration: Number(duration) || 15,
            }

            queue.push(doc)
        }

        console.log('Will add queue, ', queue)
        props.addMeetings(queue)
    }



    return (
        <div className='AddMeetingContainer'>
            <div className='AddMeetingInner'>
                <h4>Add a new meeting:</h4>
                <form onSubmit={e => createMeeting(e)}>
                    <div>
                        <label htmlFor="timestamp">Set Timestamp:</label>
                        <input type="datetime-local" name="timestamp" onChange={e => setTimestamp(e.target.value)}/>
                    </div>

                    <h5>Optional: Set duration and number of repeats</h5>

                    <div>
                        <label htmlFor="duration">Duration (minutes):</label>
                        <input type="number" name="duration" onChange={e => setDuration(e.target.value)}/>
                    </div>
                    <div>
                        <label htmlFor="repeats">Repeats:</label>
                        <input type="number" name="repeats" onChange={e => setRepeats(e.target.value)}/>
                    </div>
                    <div>
                        <input type="submit"/>
                    </div>
                </form>
            </div>
        </div>
    )
} 


export default AddMeeting;