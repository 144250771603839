import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBKpMrtdx-vHnqZARAv9W_qw_FvoPLg-KU",
    authDomain: "cme-bookings-7b8c1.firebaseapp.com",
    databaseURL: "https://cme-bookings-7b8c1.firebaseio.com",
    projectId: "cme-bookings-7b8c1",
    storageBucket: "cme-bookings-7b8c1.appspot.com",
    messagingSenderId: "32442536886",
    appId: "1:32442536886:web:8f320b00f965aced406717"
  };


firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const firestore = firebase.firestore;

export const functions = firebase.functions();

export const auth = firebase.auth();
export const authentication = firebase.auth;
  
