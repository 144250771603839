import React from 'react';

import { Route, Switch } from 'react-router-dom';

import BookingContainer from './components/BookingContainer/BookingContainer';
import Admin from './components/Admin/Admin';

import './App.css';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/admin" component={Admin} exact/>
        <Route path="/:event/:meeting" component={BookingContainer} />
      </Switch>
    </div>
  );
}

export default App;
