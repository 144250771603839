import React, { Component } from 'react';

import moment from 'moment';

import DateBar from '../DateBar/DateBar';
import ContentContainer from '../ContentContainer/ContentContainer';

import { db } from '../../services/firebase';

import styles from './BookingContainer.module.css';
import { render } from '@testing-library/react';

// const BookingContainer = (props) => {
class BookingContainer extends Component {
    
    // State
    // const [bookings, setBookings] = useState({})


    constructor(props) {
        super(props)

        this.state = {
            bookings: {},
            selectedDate: '',
            selectedMeetingId: null,
        }
        
        console.log(`Event: ${props.match.params.event}, Meeting: ${props.match.params.meeting}`)
    }

    contextData = {
        event: this.props.match.params.event,
        meeting: this.props.match.params.meeting
    }


    // Get the meetings from db
    retrieveData = async (conferenceEvent, conferenceMeeting) => {
        console.log('retrieveData')
        const snapshot = await db.collection('events').doc(conferenceEvent).collection('bookables').doc(conferenceMeeting).collection('meetings').get()
                            .catch(error => console.log(error))

        let result = [];

        let bookingsObj = {}

        /*if (!shapshot) {
            console.error('Error, no snapshot');
            return;
        }*/

        if (snapshot.empty) {
            console.log('No results!');
            return;
        }

        
        snapshot.forEach(doc => {
            result.push({id: doc.id, data: doc.data()})
        })

        console.log(result)

        result.forEach(entry => {
            const timestamp = entry.data.timestamp.toDate();
            const momentTS = moment(timestamp)
            const uniqueDay = momentTS.format('YYYYMMDD')

            if (!bookingsObj[uniqueDay]) {
                bookingsObj[uniqueDay] = {
                    title: momentTS.format('dddd Do MMMM'),
                    bookings: [entry]
                }
            } else {
                bookingsObj[uniqueDay].bookings.push(entry)
            }

        })

        // Add meetings to state
        this.setState({bookings: bookingsObj});
        
        // Set the first date as active
        this.setSelectedDate(Object.keys(bookingsObj)[0]);

    }

    // Sets the active date on the top bar
    setSelectedDate = (date) => {
        this.setState({selectedDate: date});
        console.log('Sected date is ', date);
    }

    setSelectedMeeting = (meetingId) => {
        this.setState({selectedMeetingId: meetingId});
        console.log('Selected meeting is ', meetingId);
    }

    componentDidMount() {
        this.retrieveData(this.props.match.params.event, this.props.match.params.meeting);
    }

    

    render() {


        return (
            <div className={styles.BookingContainer}>
                <DateBar bookings={this.state.bookings} selectedDate={this.state.selectedDate} setSelectedDate={this.setSelectedDate}/>
                <ContentContainer contextData={this.contextData} bookings={this.state.bookings[this.state.selectedDate]} selectedMeetingId={this.state.selectedMeetingId} setSelectedMeeting={this.setSelectedMeeting}/>
            </div>
        )

    }


}

export default BookingContainer;