import React, { useEffect, useState } from 'react';

import { auth, authentication, db, firestore } from '../../services/firebase';

import SignIn from './SignIn';
import Meetings from './Meetings';
import AddMeeting from './AddMeeting';
import Emails from './Emails';

import './Admin.css'

const Admin = () => {

    const [currentUser, setCurrentUser] = useState(null);
    const [availableEvents, setAvailableEvents] = useState([]);
    const [bookingEventName, setBookingEventName] = useState('demoevent');
    const [bookables, setBookables] = useState([]);
    const [selectedBookableId, setSelectedBookableId] = useState('')
    const [selectedBookable, setSelectedBookable] = useState({});
    const [bookableMeetings, setBookableMeetings] = useState([]);
    const [url, setUrl] = useState('');


    // get current user
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            setCurrentUser(user)
        })
    })

    // Retrieve all meetings for the event
    useEffect(() => {
        setSelectedBookableId(null);
        getData();
    }, [bookingEventName])


    // Get the available meetings/bookingEventNames
    useEffect(() => {
        const availableEventsRef = db.collection('events');

        const tempAvailableEvents = [];
        availableEventsRef.get().then(snapshot =>  {
            snapshot.forEach(doc => {
                const name = doc.data().name;
                const id = doc.id;

                tempAvailableEvents.push({name, id});
            })

            setAvailableEvents(tempAvailableEvents);
        })
    }, [])


    // Set the url of the bookings
    useEffect(() => {
        if (selectedBookableId && bookingEventName) {
            setUrl(`https://bookings.prsnt.live/${bookingEventName}/${selectedBookableId}`)
        }
    }, [selectedBookableId, bookingEventName])


    useEffect(() => {
        if (!selectedBookableId) {
            setSelectedBookable(null);
            setBookableMeetings(null);
            return;
        }

        const meetingsRef = db.collection('events').doc(bookingEventName).collection('bookables').doc(selectedBookableId).collection('meetings').orderBy('timestamp');
        const bookableMetaRef = db.collection('events').doc(bookingEventName).collection('bookables').doc(selectedBookableId);

        // get meta for the bookable
        bookableMetaRef.onSnapshot(doc => {
            const data = doc.data();

            setSelectedBookable(data);
        })


        // listen to meeting collection updates
        meetingsRef.onSnapshot(snapshot => {
            let results = [];

            snapshot.forEach(entry => {
                results.push({id: entry.id, data: entry.data()})
            })

            console.log(results)
            setBookableMeetings(results)
        })
    }, [selectedBookableId]);


    const signInUser = (email, password) => {
        auth.signInWithEmailAndPassword(email, password).catch(err => {
            console.error('Error signing in ', err)
        });
    }

    const signOutUser = () => {
        auth.signOut();
    }


    // const getCurrentUser = () => {
    //     auth.onAuthStateChanged(user => {
    //         setCurrentUser(user)
    //     })
    // }

    // getCurrentUser();


    const getData = async () => {
        const bookablesRef = db.collection('events').doc(bookingEventName).collection('bookables');
        const metaRef = db.collection('events').doc(bookingEventName);


        bookablesRef.onSnapshot(snapshot => {
            let results = []

            snapshot.forEach(entry => {
                results.push({id: entry.id, data: entry.data()})
            })

            setBookables(results)
        })


    }

    const addMeetings = async (meetings) => {
        const meetingsRef = db.collection('events').doc(bookingEventName).collection('bookables').doc(selectedBookableId).collection('meetings');

        if ((!meetings) || !(meetings.length > 0)) {
            console.error('Invalid meeting input')
            return null;
        }

        let batch = db.batch();

        meetings.forEach(entry => {
            const newRef = meetingsRef.doc();

            batch.set(newRef, entry)
        })

        batch.commit().then(() => {
            console.log('Batch completed')
        }).catch(err => {
            console.error('Batch error ', err)
        })

    }


    // Switch between available/unavailable
    const setAvailability = (id, status) => {
        const meetingRef = db.collection('events').doc(bookingEventName).collection('bookables').doc(selectedBookableId).collection('meetings').doc(id);

        let res;

        if (status) {
            res = window.confirm('Are you certain that there are no attendees for this meeting? \n ⚠ This action will not remove/cancel any attendees ⚠')
        } else {
            res = true;
        }

        if (res) {
            meetingRef.set({isBookable: status}, {merge: true}).catch(err => {
                console.error('Error updating availability status ', err)
            })
        }
        
    }


    // Delete meeting
    const deleteMeeting = (id) => {
        const meetingRef = db.collection('events').doc(bookingEventName).collection('bookables').doc(selectedBookableId).collection('meetings').doc(id);

        let res = window.confirm('Are you certain that there are no attendees for this meeting and you want to delete it? \n ⚠ This action will not remove/cancel any attendees ⚠');

        if (res) {
            meetingRef.delete().catch(err => {
                console.error('Error updating availability status ', err)
            })
        }
        
    }


    // Add an email address to the bookable
    const handleAddEmail = (e, email, bookableId) => {
        e.preventDefault();
        const bookableRef = db.collection('events').doc(bookingEventName).collection('bookables').doc(bookableId);
        bookableRef.update({
            emails: firestore.FieldValue.arrayUnion(email)
        })
    }

    // Remove an email address from the bookable
    const handleRemoveEmail = (email, bookableId) => {
        const bookableRef = db.collection('events').doc(bookingEventName).collection('bookables').doc(bookableId); 
        
        if (window.confirm(`Are you sure you want to remove ${email}?`)) {
            bookableRef.update({
                emails: firestore.FieldValue.arrayRemove(email)
            })
        }
    }




    if (!currentUser) {
        return (
            <div>
                <SignIn signInFunction={signInUser}/>
            </div>
        )
    }

    return (
        <div className="AdminContainer">

            <h1>Admin: {bookingEventName}</h1>

            <div>
                <div>Available events:</div>
                <ul>{availableEvents.map(entry => <li style={bookingEventName === entry.id ? {fontWeight: 'bold'} : {cursor: 'pointer'}} key={entry.id} onClick={() => setBookingEventName(entry.id)}>{entry.name}</li>)}</ul>
            </div>
            
            <div>
                <div>Available bookables:</div>
                <ul>{bookables.map(entry => <li style={selectedBookableId === entry.id ? {fontWeight: 'bold'} : {cursor: 'pointer'}} key={entry.id} onClick={() => setSelectedBookableId(entry.id)}>{entry.data.name}</li>)}</ul>
            </div>

            <div style={{padding: '15px'}}>
                {url ? <div>Now editing (click to copy): <span title={'Copy to clipboard'} style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={() => navigator.clipboard.writeText(url)}>{url}</span></div> : <div></div>}
            </div>

            <div>
                <Meetings bookableMeetings={bookableMeetings} setAvailability={setAvailability} deleteMeeting={deleteMeeting}/>
            </div>

            <div>
                <AddMeeting addMeetings={addMeetings} selectedBookable={selectedBookable} />
            </div>

            <div>
                { selectedBookable ? <Emails selectedBookable={selectedBookable} selectedBookableId={selectedBookableId} addEmail={handleAddEmail} removeEmail={handleRemoveEmail} /> : null }
            </div>

            <footer>
                <div><span style={{float:'left'}}>Is signed in as {currentUser.uid} / {currentUser.email}</span><span style={{float:'right'}}><a href='#' title='Logout' onClick={signOutUser}>Logout</a></span></div>
            </footer>   
        </div>
    )
}

export default Admin;