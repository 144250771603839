import React from 'react';

const Meetings = (props) => {

    const bookableMeetings = props.bookableMeetings;


    if (!bookableMeetings) {
        return null
    }


    return (
        <div>
            <table className='MeetingTable'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Timestamp</th>
                        <th>Disable</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {bookableMeetings.map(entry => 
                    
                        <tr className={entry.data.isBookable ? '' : 'unavailable'} key={entry.id}>
                            <td>{entry.id}</td>
                            <td>{entry.data.timestamp.toDate().toString()}</td>
                            {
                                entry.data.isBookable ?
                                    <td className="TableSelection" onClick={() => props.setAvailability(entry.id, false)}>Make Unavailable</td>
                                    :
                                    <td className="TableSelection" onClick={() => props.setAvailability(entry.id, true)}>Make Available ⚠</td>
                            }
                            <td className="TableSelection" onClick={() => props.deleteMeeting(entry.id)}>X</td>
                            
                        </tr>
                        
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default Meetings;