import React, { useState } from 'react';

const SignIn = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signInUser = (e) => {
        e.preventDefault();

        props.signInFunction(email, password)
    }

    return (
        <div>
            <form className="admin_signInForm" onSubmit={e => signInUser(e)}>
                <div>
                    <input name="email" type="email" onChange={e => setEmail(e.target.value)}/>
                </div>
                <div>
                    <input name="password" type="password" onChange={e => setPassword(e.target.value)}/>
                </div>
                <div>
                    <input type="submit" />
                </div>
            </form>
        </div>
    )
}

export default SignIn;